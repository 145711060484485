import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCog,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons"

const Services = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Our Work" />
      <PageTitle
        title="Our Work"
        description="Find out about our featured client projects."
      />
      <section id="intro" class="section section-padding">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12">
              <div class="section-heading">
                <p class="lead mb-4">
                  QuoteRunner - Instant business energy quotes online
                </p>
                <p class="strapline pl-4 pr-4">
                  How do you provide a simple way for SMEs to manage their
                  energy supplies?
                </p>
                <p class="strapline pl-4 pr-4">
                  You provide a platform for businesses to compare the best
                  deals. Avoiding the having to call round suppliers or brokers
                  or having to deal with brokers trying the hard sell just to
                  then spend 30 minutes on the phone sorting out the contract.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5 col-sm-12 col-md-6 mb-5 mb-lg-0">
              <div className="carousel slide carousel--image" id="carousel1">
                <ol className="carousel-indicators">
                  <li
                    data-target="#carousel1"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li data-target="#carousel1" data-slide-to="1"></li>
                  <li data-target="#carousel1" data-slide-to="2"></li>
                  <li data-target="#carousel1" data-slide-to="3"></li>
                  <li data-target="#carousel1" data-slide-to="4"></li>
                </ol>
                <div className="carousel-inner">
                  <div class="carousel-item active">
                    <img
                      src="/images/quoterunner-app1.png"
                      alt="feature bg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/images/quoterunner-app2.png"
                      alt="feature bg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/images/quoterunner-app3.png"
                      alt="feature bg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/images/quoterunner-app4.png"
                      alt="feature bg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="carousel-item">
                    <img
                      src="/images/quoterunner-admin2.png"
                      alt="feature bg"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <a
                  className="carousel-control-prev"
                  href="#carousel1"
                  role="button"
                  data-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="carousel-control-next"
                  href="#carousel1"
                  role="button"
                  data-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Next</span>
                </a>
              </div>
            </div>

            <div class="col-lg-7 pl-4">
              <div class="mb-4">
                <h3 class="mb-3">The challenge</h3>
                <p>
                  Cost Advice Services Limited wanted a new end-to-end method
                  for SME customers to search, compare, select and importantly
                  sign contracts for energy deals.
                </p>
                <p>
                  Invently know how to make software projects a success. The
                  first step is to get a shared vision and understanding of the
                  project and use our technical knowledge to analyse what is
                  possible and feasible to get a product to market as quickly as
                  possible.
                </p>
                <p>
                  We built a mobile app that works on Android and iOS. The app
                  is integrated with various 3rd party systems & data providers
                  to show customer deals that best meet their business
                  requirements.
                </p>
                <p>
                  As well as providing a list of the best energy deals, the app
                  needed to provide the easiest way for businesses to manage
                  their supplies and sites. As well as screens to manually enter
                  these details we used native mobile functionality for
                  customers to also take photos of existing bills and contracts
                  to be used to set up their sites & supplies for them.
                </p>
                <p>
                  The Invently team integrated with a tried & tested document
                  signing provider provided an easy way for customers to sign
                  contracts for their chosen deal without leaving the app.
                </p>
              </div>

              <h3 class="mb-3">How we helped</h3>
              <ul class="about-list mt-0">
                <li>
                  <h5 class="mb-2">
                    <FontAwesomeIcon icon={faCog} className="text-brand mr-2" />
                    Discovery
                  </h5>
                  <p>
                    The first step in all our projects is to get the key
                    stakeholders in a room for the purpose of ideating and
                    defining expected outcomes. Off the back of this meeting, we
                    defined the scope and our technical team reviewed numerous
                    document signing providers such as DocuSign, Adobe Sign &
                    Zoho Sign to find best fit for the QuoteRunner product.
                  </p>
                </li>

                <li>
                  <h5 class="mb-2">
                    <FontAwesomeIcon icon={faCog} className="text-brand mr-2" />
                    Third-Party Integrations
                  </h5>
                  <p>
                    Integrating with third-party systems is something our
                    technical team do best. As well as integrating with a
                    document signing provider, we integrated with various other
                    systems to retrieve available prices, generate contract
                    documents for signing, as well as provide lookups for
                    MPAN/MPR numbers, addresses and bank account information.
                  </p>
                </li>

                <li class="row-start">
                  <h5 class="mb-2">
                    <FontAwesomeIcon icon={faCog} className="text-brand mr-2" />
                    Mobile Apps
                  </h5>
                  <p>
                    The QuoteRunner app needed to be accessible to as many SMEs
                    as possible. We therefore published the app to the Google
                    Play Store, Apple App Store as well as provided a web
                    version.
                  </p>
                </li>
                <li>
                  <h5 class="mb-2">
                    <FontAwesomeIcon icon={faCog} className="text-brand mr-2" />
                    Admin system
                  </h5>
                  <p>
                    A back-office admin system was created to allow admin staff
                    to manage customers, in app content, view generated
                    documents, such a contracts and letters of authority and
                    manage commissions generated.
                  </p>
                </li>
              </ul>
              <div class="tech-list">
                <h5 class="mt-6">Technologies used</h5>
                <ul>
                  <li>.NET Core</li>
                  <li>VueJS</li>
                  <li>Capacitor</li>
                  <li>Azure</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-5 mt-5">
          <div className="row">
            <div className="col-lg-8 m-auto col-sm-12 col-md-12">
              <div className="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row">
                      <div className="col-lg-12 col-sm-12">
                        <div className="testimonial-content style-2">
                          <p>
                            <FontAwesomeIcon
                              icon={faQuoteLeft}
                              className="text-brand mr-2"
                            />
                            It was fantastic to collaborate with Invently on
                            this project. <br />
                            Their work is highly professional, and their
                            technical experience shined though making light work
                            of the app's complex technical requirements.
                            <FontAwesomeIcon
                              icon={faQuoteRight}
                              className="text-brand ml-2"
                            />
                          </p>
                          <div className="author-text">
                            <h5>Stephen Ball</h5>
                            <p>Cost Advice Services Ltd</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
